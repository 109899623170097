
import SmartlinkAd from '../SmartlinkAd/SmartlinkAd'
import Hero from './Hero/Hero'

function Home() {
    return (
        <div>

            <Hero />
        </div>
    )
}

export default Home