import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyApDqe5yMLnZo21j9aRTWAZNOVVXLNCID0",
  authDomain: "comingweekendcom.firebaseapp.com",
  projectId: "comingweekendcom",
  storageBucket: "comingweekendcom.appspot.com",
  messagingSenderId: "340354975554",
  appId: "1:340354975554:web:04547aff9960b037c06adb",
  measurementId: "G-5GGH22G8DF"
};
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);