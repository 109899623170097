import Navbar from "./components/Navbar/Navbar.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Home from "./components/Home/Home"
import Support from "./components/Support/Support";

import Educational from "./components/Allevent/Educational/Educational.js"
import CreateEducational from "./components/Allevent/Educational/CreateEducational.jsx";
import Festivals from "./components/Allevent/Festivals/Festivals.js";
import CreateFestivals from "./components/Allevent/Festivals/CreateFestivals.jsx";
import Gorvenemt from "./components/Allevent/Gorvenemt/Gorvenemt.js";
import CreateGorvenemt from "./components/Allevent/Gorvenemt/CreateGorvenemt.jsx";
import International from "./components/Allevent/International/International.js";
import CreateInternational from "./components/Allevent/International/CreateInternational.jsx";
import Music from "./components/Allevent/Music/Music.js";
import CreateMusic from "./components/Allevent/Music/CreateMusic.jsx";
import Sports from "./components/Allevent/Sports/Sports.js";
import CreateSports from "./components/Allevent/Sports/CreateSports.jsx";
import SmartlinkAd from "./components/SmartlinkAd/SmartlinkAd.js";


function App() {
  return (
    <Router>
      <Navbar />
      <SmartlinkAd />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Support" element={<Support />} />

        <Route path="/Educational" element={<Educational />} />
        <Route path="/CreateEducational" element={<CreateEducational />} />
        <Route path="/Festivals" element={<Festivals />} />
        <Route path="/CreateFestivals" element={<CreateFestivals />} />
        <Route path="/Gorvenemt" element={<Gorvenemt />} />
        <Route path="/CreateGorvenemt" element={<CreateGorvenemt />} />
        <Route path="/International" element={<International />} />
        <Route path="/CreateInternational" element={<CreateInternational />} />
        <Route path="/Music" element={<Music />} />
        <Route path="/CreateMusic" element={<CreateMusic />} />
        <Route path="/Sports" element={<Sports />} />
        <Route path="/CreateSports" element={<CreateSports />} />



      </Routes>
    </Router>

  );
}

export default App;
