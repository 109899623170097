import React from 'react'
import "./Heading.css"
function Heading(title) {
    return (
        <div className='Headignh6'>
            <h6>{title.title} </h6>
        </div>

    )
}

export default Heading