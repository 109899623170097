import React from 'react'
import "./Hero.css"
import { Link } from 'react-router-dom';

function Hero() {
    return (
        <div >

            <div id="why-us" class="why-us">

                <div class="container" data-aos="fade-up">
                    <div class="row">
                        <div class="col-lg-4 d-flex align-items-stretch">
                            <div class="content">
                                <h3> Welcome to</h3>
                                <p>
                                    ComingWeekend.com is your great guide to exhilarating events around the globe. At Coming weekend .com we are impassioned about keeping you in touch with the most recent and most happening events taking place worldwide whether you are a Music aficionado, an adventurer, a sports enthusiast, a culture vulture, or simply have craze for exploring new experiences we have something outstanding for you
                                </p>
                                {/* <div class="text-center">
                                    <a href="about.html" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div class="icon-boxes d-flex flex-column justify-content-center">
                                <div class="row">
                                    <div class="col-xl-4 d-flex align-items-stretch">
                                        <div class="icon-box mt-4 mt-xl-0">
                                            <i class="fa fa-map" aria-hidden="true"></i>
                                            <h4>Embark on Thrilling Tours</h4>
                                            <p>Wanderlust calling? Our website showcases an array of thrilling tours and travel destinations that cater to every wanderer's dream. </p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 d-flex align-items-stretch">
                                        <div class="icon-box mt-4 mt-xl-0">
                                            <i class="fa fa-futbol-o" aria-hidden="true"></i>
                                            <h4>Cheer for Sporting Excellence </h4>
                                            <p>Stay on top of the sporting world with our extensive coverage of sports events and championships from around the globe.</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 d-flex align-items-stretch">
                                        <div class="icon-box mt-4 mt-xl-0">
                                            <i class="fa fa-gift" aria-hidden="true"></i>
                                            <h4>Celebrate Festivals</h4>
                                            <p>Embrace the joy and traditions of festivals worldwide with vibrant celebrations and rich cultural experiences!</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="features" class="features">
                <div class="container" data-aos="fade-up">
                    <div class="row" data-aos="zoom-in" data-aos-delay="100">
                        <div class="col-lg-3 col-md-4">
                            <Link to="/Music" style={{ textDecoration: "none" }}>
                                <div class="icon-box">
                                    <i class="fa fa-music" style={{ color: "#e361ff" }}></i>
                                    <h3 style={{ color: "#561582" }} >Music</h3>
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
                            <Link to="/Sports" style={{ textDecoration: "none" }}>   <div class="icon-box">
                                <i class="fa fa-trophy" aria-hidden="true" style={{ color: "#ffbb2c" }}></i>
                                <h3 style={{ color: "#947309" }}>Sports</h3>
                            </div></Link>
                        </div>

                        <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
                            <Link to="/Gorvenemt" style={{ textDecoration: "none" }}>
                                <div class="icon-box">
                                    <i class="fa fa-balance-scale" aria-hidden="true" style={{ color: "#29cc61" }}></i>
                                    <h3 style={{ color: "#0a5c91" }}>Government</h3>
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-4 mt-4">
                            <Link to="/International" style={{ textDecoration: "none" }}>  <div class="icon-box">
                                <i class="fa fa-flag" aria-hidden="true" style={{ color: "#ff5828" }}></i>
                                <h3 style={{ color: "#6b0916" }}>International</h3>
                            </div></Link>
                        </div>
                        <div class="col-lg-3 col-md-4 mt-4">
                            <Link to="/Educational" style={{ textDecoration: "none" }}>
                                <div class="icon-box">
                                    <i class="fa fa-graduation-cap" aria-hidden="true" style={{ color: "#b2904f" }}></i>
                                    <h3 style={{ color: "#a16512" }}>Educational</h3>
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-4 mt-4">
                            <Link to="/Festivals" style={{ textDecoration: "none" }}>
                                <div class="icon-box">
                                    <i class="fa fa-users" aria-hidden="true" style={{ color: "#11dbcf" }}></i>
                                    <h3 style={{ color: "#4cc4d9" }}>Festivals</h3>
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-4 mt-4">
                            <Link to="/" style={{ textDecoration: "none" }}> <div class="icon-box">
                                <i class="fa fa-and" aria-hidden="true" style={{ color: "red" }}>@</i>
                                <h3 style={{ color: "#237508" }}>more</h3>
                            </div></Link>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}
export default Hero