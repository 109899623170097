import React, { useState, useEffect } from 'react';
import Heading from '../../Common/Heading/Heading';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { auth, db } from "../../../Source/firebaseConfig";
import DeleteArticle from "./Deletelike/DeleteArticle";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import Loder from '../../Common/Loder/Loder';
import Footer from '../../Common/Footer/Footer';
import '../Educational/Eductional.css';

function Sports() {
    const [articles, setArticles] = useState([]);
    const [user] = useAuthState(auth);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    useEffect(() => {
        const articleRef = collection(db, "Sports");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
        });
    }, []);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentArticles = articles.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(articles.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className='container'>
                <Heading title="Sports Events" />

                <h5 style={{ color: "black", width: "150px", textAlign: "center", textTransform: "uppercase" }}>
                    <Link to="/CreateSports" style={{ textDecoration: "none" }}>Post Event</Link>
                </h5>
            </div>
            {articles.length === 0 ? (
                <div className="container">
                    <Loder />
                </div>
            ) : (
                currentArticles.map(
                    ({
                        id,
                        title,
                        description,
                        imageUrl,
                        createdAt,
                        userId,
                        likes,
                    }) => (
                        <div className="container py-1" key={id}>
                            <div className="deleteArtical">
                                {user && user.uid === userId && (
                                    <DeleteArticle id={id} imageUrl={imageUrl} />
                                )}
                            </div>
                            <div className="card">
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <div className="image-wrapper">
                                            <img
                                                src={imageUrl}
                                                alt={title}
                                                className="w-100 actual-image"
                                                loading='lazy'
                                                onLoad={(e) => e.target.classList.add('loaded')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card-block">
                                            <h4 className="card-title" style={{ color: "#00c0ee" }}>{title}</h4>
                                            <p className="card-text" style={{ lineHeight: "32px" }}>{description}</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                )
            )}

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <nav>
                    <ul className="pagination">
                        {[...Array(totalPages)].map((_, i) => (
                            <li key={i} className={`page-item ${i + 1 === currentPage ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <Footer />
        </div>
    );
}

export default Sports;
