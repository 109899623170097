import React from "react";

const SmartlinkAd = () => {
    return (
        <div style={styles.container}>
            <a
                href="https://soakensissies.com/iJE9xyMeo21/98211"
                target="_blank"
                rel="noopener noreferrer"
                style={styles.link}
            >
                {/* You can either use text or an image */}
                {/* Uncomment the image line if you want a banner instead */}
                {/* <img src="path_to_your_image.jpg" alt="Festival Offer" style={styles.image} /> */}
                <button style={styles.button}>Check out  Offers!</button>
            </a>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        // backgroundColor: "#f8f9fa",
        borderRadius: "10px",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginTop: "20px",
    },
    link: {
        textDecoration: "none",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "blue",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
    },
    image: {
        maxWidth: "100%",
        borderRadius: "10px",
    },
};

export default SmartlinkAd;
