import React from 'react';
import { Link } from 'react-router-dom';
import SmartlinkAd from '../../SmartlinkAd/SmartlinkAd';

import "./Footer.css";

function Footer() {
    return (<> <SmartlinkAd /> <div id='footer' style={{ textAlign: "center" }}>

        <section className="deneb_cta">

            <div className="container">

                <div>
                    <p className="contact-info" style={{ textAlign: "center" }}></p>

                    <div className="copyright" style={{ fontSize: "12px" }}>
                        <span className="contact-label">Address:</span> Murtazabad Hunza
                        <span className="contact-label">Cell:</span> +923414209244
                        <span className="contact-label">Email:</span> www.info@comingweekend.com


                    </div>

                    <div> &copy; Copyright <strong><span>Hamleen PVT LTD</span></strong> All Rights Reserved<br />
                        <span className="credits">Designed by <span>Hamleen</span> PVT LTD.<br /></span></div>
                    <span>
                        <i className="fa fa-chevron-right" style={{ marginTop: "30px", padding: "5px 0px" }}></i>
                        <Link to="/Support" style={{ color: "orange" }}>Privacy policy</Link>
                    </span>
                </div>
            </div>
        </section>
    </div> </>

    );
}

export default Footer;
