import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../../Source/firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
export default function CreateGorvenemt() {
  const [user] = useAuthState(auth);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });
  const [progress, setProgress] = useState(0);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };
  const handlePublish = () => {
    if (!formData.title || !formData.description || !formData.image) {
      alert("Please fill all the fields");
      return;
    }
    const storageRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );
    const uploadImage = uploadBytesResumable(storageRef, formData.image);
    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
        });
        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Gorvenemt");
          addDoc(articleRef, {
            title: formData.title,
            description: formData.description,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),
            createdBy: user.displayName,
            userId: user.uid,
            likes: [],
            comments: []
          })
            .then(() => {
              toast("Event added successfully", { type: "success" });
              setProgress(0);
            })
            .catch((err) => {
              toast("Error adding Event", { type: "error" });
            });
        });
      }
    );
  };
  return (
    <div className="container">
      <div className=" p-3 mt-3 " >
        {!user ? (
          <>
            <Link to="/Login">
              <h2 style={{ color: "#3fa199f8" }}>
                LOGIN TO CREATE A EVENT
              </h2>
            </Link>
            Don't have an account? <Link to="/register" style={{ textDecoration: "none" }}><span style={{ color: "#3fa199f8" }}>Signup</span></Link>
          </>
        ) : (
          <>
            <h2 style={{ color: "#00c0ee" }}>Create Government Event</h2>
            <div className="form-group">
              <label htmlFor="" > Title</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={formData.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* description */}
            <label htmlFor=""> Description</label>
            <textarea
              name="description"
              className="form-control"
              cols={10}
              rows={10}
              value={formData.description}
              onChange={(e) => handleChange(e)}
            />
            {/* image */}
            <label htmlFor="">Image</label>
            <input
              type="file"
              name="image"
              accept="image/*"
              className="form-control"
              onChange={(e) => handleImageChange(e)}
            />
            {progress === 0 ? null : (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped mt-2"
                  style={{ width: `${progress}%` }}
                >
                  {`uploading image ${progress}%`}
                </div>
              </div>
            )}
            <button
              className="form-control btn-primary mt-2  "
              onClick={handlePublish}
            >
              Publish
            </button>
          </>
        )
        }
      </div >
    </div >
  );
}