import React from 'react'
import { Audio } from 'react-loader-spinner'
import "./Loder.css"
function Loder() {
    return (
        <div class="🤚">
            <div class="👉"></div>
            <div class="👉"></div>
            <div class="👉"></div>
            <div class="👉"></div>
            <div class="🌴"></div>
            <div class="👍"></div>
        </div>
    )
}

export default Loder