import React from 'react';
import Footer from '../Common/Footer/Footer';

function Support() {
    return (<><div className='container'>
        <div className="fromgfetqute">
            <h3 style={{ color: "#3fa199f8", paddingTop: "10px" }}>Privacy Policy</h3>
            <br />
            <p>We are the sole owners of the information collected on this site. We only have access to/collect information
                that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to any individual or organization. We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Unless you ask us not to, we may contact
                you via email in the future to tell you about new jobs or services, or changes to this privacy policy.</p>

            <h3 style={{ color: "#f1c40f" }}>Terms of Service</h3>
            <p className='secoundparm' style={{ color: "red" }}>
                Terms you agree to when you use AILogixX
            </p>
            <p>After becoming an COMINGWEEKEND.COM user, you need to be aware of the following points:
                COMINGWEEKEND.COM never charges a single penny, so you don't have to pay anyone within or outside the site.</p>

            <h3 style={{ color: "#f1c40f" }}>Community Standards</h3>
            <p className='secoundparm'>What's not allowed and how to report abuse</p>
            <p>COMINGWEEKEND.COMis not a dating site. Users are not allowed to post adult content. If you see something inappropriate, it is your right to report that person.</p>

            <h4 style={{ color: "#f1c40f" }}>How to contact customer support</h4>
            <p>Contact customer support AT hello@comingweekend.com  explaining your issues meticulously.</p>
        </div>
    </div>
        <Footer /></>);

}

export default Support;
