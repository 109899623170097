import { deleteDoc, doc } from "firebase/firestore";
import React from "react";
import { db, storage } from "../../../../Source/firebaseConfig";
import { toast } from "react-toastify";
import { deleteObject, ref } from "firebase/storage";
export default function DeleteArticle({ id, imageUrl }) {
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        await deleteDoc(doc(db, "Music", id));
        toast("Event deleted successfully", { type: "success" });
        const storageRef = ref(storage, imageUrl);
        await deleteObject(storageRef);
      } catch (error) {
        toast("Error deleting Event", { type: "error" });
        console.log(error);
      }
    }
  };
  return (
    <div>
      <i
        className="fa fa-trash"
        onClick={handleDelete}
        style={{ cursor: "pointer", fontSize: "20px", color: "red" }}
      />
    </div>
  );
}
